import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['source', 'trigger']

  copy () {
    const oldText = this.triggerTarget.innerHTML
    this.triggerTarget.innerText = 'Copied!'
    setTimeout(() => {
      this.triggerTarget.innerHTML = oldText
    }, 1500)
    navigator.clipboard.writeText(this.sourceTarget.value)
  }
}
