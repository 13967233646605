import ApplicationController from './application_controller'

export default class extends ApplicationController {
  connect () {
    this.trapFocus()
  }

  dismissModal (event) {
    event.preventDefault()
    event.stopPropagation()

    const modalContainer = document.querySelector('#modal')

    if (modalContainer) {
      modalContainer.innerHTML = ''
    }
  }

  getFocusableElements (container) {
    return container.querySelectorAll('a, button, input, textarea, select, details, [tabindex]:not([tabindex="-1"])')
  }

  getInteractiveElements (container) {
    return container.querySelectorAll("trix-editor, input:not([type='hidden']):not([type='button']):not([type='submit']):not([disabled]), textarea, select")
  }

  trapFocus () {
    const modal = document.querySelector('.modal')

    const interactiveElements = this.getInteractiveElements(modal)
    const focusableElements = this.getFocusableElements(modal)

    if (interactiveElements.length !== 0) {
      interactiveElements[0].focus()
    } else if (interactiveElements.length === 0 && focusableElements.length !== 0) {
      focusableElements[0].focus()
    }

    if (focusableElements.length !== 0) {
      const firstFocusableElement = focusableElements[0]
      const lastFocusableElement = focusableElements[focusableElements.length - 1]

      modal.addEventListener('keydown', event => {
        if (event.key === 'Tab') {
          if (event.shiftKey) {
            if (document.activeElement === firstFocusableElement) {
              lastFocusableElement.focus()
              event.preventDefault()
            }
          } else {
            if (document.activeElement === lastFocusableElement) {
              firstFocusableElement.focus()
              event.preventDefault()
            }
          }
        }

        if (event.key === 'Escape') {
          this.dismissModal(event)
        }
      })
    } else {
      modal.addEventListener('keydown', event => {
        if (event.key === 'Escape') {
          this.dismissModal(event)
        }
      })
    }
  }
}
