import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect () {
    document.addEventListener('trix-initialize', (evt) => {
      this.setupListeners(evt)
    })
  }

  setupListeners (evt) {
    const data = {
      event: evt,
      target: evt.target,
      toolbar: evt.target.toolbarElement,
      linkButton: evt.target.toolbarElement.querySelector('input[value="Link"]'),
      urlField: evt.target.toolbarElement.querySelector('input[name="href"]')
    }

    const linkButton = data.linkButton
    const urlField = data.urlField
    urlField.addEventListener('keydown', (e) => {
      if (e.key === 'Enter') {
        this.prependProtocol(urlField)
      }
    })
    linkButton.addEventListener('click', () => {
      this.prependProtocol(urlField)
    })
  }

  prependProtocol (urlField) {
    if (urlField.value.indexOf('http') !== 0) {
      urlField.value = 'https://' + urlField.value
    }
  }
}
