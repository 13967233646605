// import ApplicationController from './application_controller'
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['trigger', 'editor', 'focus']

  showInput (evt) {
    evt.preventDefault()
    evt.stopPropagation()
    this.triggerTarget.classList.add('hidden')
    this.editorTarget.classList.remove('hidden')
    this.focusTarget.focus()
  }

  hideInput () {
    this.triggerTarget.classList.remove('hidden')
    this.editorTarget.classList.add('hidden')
  }
}
