import ApplicationController from './application_controller'

export default class extends ApplicationController {
  saveImportedChanges (evt) {
    evt.preventDefault()
    evt.stopPropagation()
    const card = evt.target.closest('.admin-import-card')
    const form = card.querySelectorAll('form')[0]
    card.hidden = true
    form.requestSubmit()
    const adminImportCards = document.querySelectorAll('.admin-import-card')
    let remainingCardCount = 0
    adminImportCards.forEach((obj) => {
      if (window.getComputedStyle(obj).display === 'block') {
        remainingCardCount += 1
      }
    })
    if (remainingCardCount === 0) {
      setTimeout(() => window.location.reload(), 500)
    }
  }
}
