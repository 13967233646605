import ApplicationController from './application_controller'

export default class extends ApplicationController {
  showSolutionFirmReviewSummaryForm () {
    const firmId = this.element.dataset.firmId
    const summaryElement = document.getElementById(`firm-review-${firmId}-summary`)
    this.element.style.display = 'none'
    summaryElement.style.display = ''
    const editorArea = summaryElement.querySelector('trix-editor')
    editorArea.focus()
  }
}
