import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['accordionItem']

  connect () {
    if (this.hasAccordionItemTargets) {
      if ((this.accordionItemTargets.length === 1 && this.element.dataset.expandFirst !== 'false') || this.element.dataset.expandFirst === 'true') {
        this.accordionItemTargets[0].setAttribute('open', 'true')
      }
    }
  }

  toggle (event) {
    event.preventDefault()
    event.stopPropagation()
    const closing = event.target.closest('details').hasAttribute('open')
    this.accordionItemTargets.forEach((element) => {
      element.removeAttribute('open')
    })
    if (closing) {
      event.target.closest('details').removeAttribute('open')
    } else {
      event.target.closest('details').setAttribute('open', 'true')
    }
  }
}
