import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  submitForm (event) {
    const formAttribute = event.target.attributes.form
    if (formAttribute) {
      const form = document.getElementById(formAttribute.value)
      if (form) {
        form.requestSubmit()
      }
    } else {
      const form = this.element.closest('form')
      if (form) {
        form.requestSubmit()
      }
    }
  }

  static delayedSubmit

  delayedSubmitForm () {
    clearTimeout(this.delayedSubmit)
    this.delayedSubmit = setTimeout(() => {
      this.submitForm()
    }, 2500)
  }
}
