import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['draggable', 'dropzone', 'container']

  dragstart (event) {
    const dragging = event.target
    const draggingId = dragging.getAttribute('data-draggable-id')
    event.dataTransfer.setData('application/drag-key', draggingId)
    event.dataTransfer.setData(`application/dragging/${draggingId}`, draggingId)
    event.dataTransfer.effectAllowed = 'move'
  }

  dragoverDropzone (event) {
    event.preventDefault()
    event.target.classList.add('draggable--dropzone--hovered')
  }

  dragoverDraggable (event) {
    event.preventDefault()
    const draggedOver = event.target.classList.contains('draggable--draggable') ? event.target : event.target.closest('.draggable--draggable')
    const draggedOverId = draggedOver.getAttribute('data-draggable-id')
    const draggedOverIndex = parseInt(draggedOver.getAttribute('data-draggable-index'))

    const transferTypes = event.dataTransfer.types
    const matchingKey = transferTypes.find((type) => type.includes('application/dragging/'))
    if (matchingKey === undefined) {
      return
    }
    const draggingId = matchingKey.split('/')[2]

    const dragging = document.querySelector(`[data-draggable-id="${draggingId}"]`)
    const draggingIndex = parseInt(dragging.getAttribute('data-draggable-index'))

    const dragDirection = draggingIndex > draggedOverIndex ? 'up' : 'down'

    this.dropzoneTargets.forEach((dropzone) => {
      dropzone.classList.remove('draggable--dropzone--active')

      if (draggedOverId !== null && dropzone.getAttribute('data-for-before-draggable-id') !== draggingId && dropzone.getAttribute('data-for-after-draggable-id') !== draggingId) {
        if (dragDirection === 'up' && dropzone.getAttribute('data-for-before-draggable-id') === draggedOverId) {
          dropzone.classList.add('draggable--dropzone--active')
        } else if (dragDirection === 'down' && dropzone.getAttribute('data-for-after-draggable-id') === draggedOverId) {
          dropzone.classList.add('draggable--dropzone--active')
        }
      }
    })
  }

  dragleaveDropzone (event) {
    event.target.classList.remove('draggable--dropzone--active')
  }

  drop (event) {
    event.preventDefault()
    const draggingId = event.dataTransfer.getData('application/drag-key')
    const dropTarget = event.target.classList.contains('draggable--dropzone') ? event.target : event.target.closest('.draggable--dropzone')

    if (this.containerTarget.dataset.autoSubmitOnReorder === 'true') {
      this.updateInputAndSubmit(draggingId, dropTarget)
    } else {
      this.sortElementsInSitu(draggingId, dropTarget)
    }
  }

  dragend (event) {
    event.preventDefault()
    const draggingId = event.dataTransfer.getData('application/drag-key')
    const dragging = document.querySelector(`[data-draggable-id="${draggingId}"]`)
    if (dragging) {
      dragging.classList.remove('draggable--dragging')
    }
    this.dropzoneTargets.forEach((dropzone) => {
      dropzone.classList.remove('draggable--dropzone--active')
      dropzone.classList.remove('draggable--dropzone--hovered')
    })
  }

  updateInputAndSubmit (draggingId, dropTarget) {
    const sortOrderInput = document.querySelector(`#drag-and-drop-sort-order-${draggingId}`)
    console.log(`Looking for input: #drag-and-drop-sort-order-${draggingId}`)
    console.log(`Found input: ${sortOrderInput}`)
    if (sortOrderInput) {
      sortOrderInput.value = dropTarget.getAttribute('data-dropzone-index')
      sortOrderInput.closest('form').requestSubmit()
    }
  }

  sortElementsInSitu (draggingId, dropTarget) {
    const dropzoneIndex = dropTarget.getAttribute('data-dropzone-index')
    const dragging = document.querySelector(`[data-draggable-id="${draggingId}"]`)
    const draggingIndex = dragging.getAttribute('data-draggable-index')
    const dragDirection = draggingIndex > dropzoneIndex ? 'up' : 'down'

    this.draggableTargets.forEach((draggable) => {
      this.bubbleDraggable(draggable, dragDirection, draggingId, draggingIndex, dropzoneIndex)
    })

    this.dropzoneTargets.forEach((dropzone) => {
      const draggableId = dropzone.getAttribute('data-for-before-draggable-id') || dropzone.getAttribute('data-for-after-draggable-id')
      const draggable = document.querySelector(`[data-draggable-id="${draggableId}"]`)
      const draggableIndex = draggable.getAttribute('data-draggable-index')
      dropzone.setAttribute('data-dropzone-index', draggableIndex)
    })
    const draggingDraggable = dragging.classList.contains('draggable--draggable') ? dragging : dragging.closest('.draggable--draggable')

    if (dragDirection === 'up') {
      const prependTarget = dropTarget.parentElement
      this.containerTarget.insertBefore(draggingDraggable, prependTarget)
    } else {
      const prependTarget = dropTarget.parentElement.nextElementSibling
      this.containerTarget.insertBefore(draggingDraggable, prependTarget)
    }
  }

  bubbleDraggable (draggable, dragDirection, draggingId, draggingIndex, dropzoneIndex) {
    const draggableIndex = draggable.getAttribute('data-draggable-index')
    const draggableId = draggable.getAttribute('data-draggable-id')

    if (draggable.getAttribute('data-draggable-id') === draggingId) {
      draggable.setAttribute('data-draggable-index', dropzoneIndex)
    } else {
      if (dragDirection === 'up') {
        if (draggableIndex >= dropzoneIndex && draggableIndex < draggingIndex) {
          draggable.setAttribute('data-draggable-index', parseInt(draggableIndex) + 1)
        }
      } else {
        if (draggableIndex <= dropzoneIndex && draggableIndex > draggingIndex) {
          draggable.setAttribute('data-draggable-index', parseInt(draggableIndex) - 1)
        }
      }
    }
    const draggableSortOrderInput = document.querySelector(`#drag-and-drop-sort-order-${draggableId}`)
    if (draggableSortOrderInput) {
      draggableSortOrderInput.value = draggable.getAttribute('data-draggable-index')
    }
  }
}
