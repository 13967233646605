// Entry point for the build script in your package.json
// import '@hotwired/turbo-rails'
import './controllers'
import 'trix'
import '@rails/actiontext'

import { Turbo } from '@hotwired/turbo-rails'

document.addEventListener('turbo:before-fetch-response', function (event) {
  if (typeof (event.detail.fetchResponse) !== 'undefined') {
    const response = event.detail.fetchResponse.response
    if (response.redirected) {
      event.preventDefault()
      Turbo.visit(response.url)
    }
  }
})
