import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect () {
    console.log('Button controller connected')
  }

  disable () {
    console.log('Disabling button')
    setTimeout(() => {
      this.element.disabled = true
    }, 10)
  }
}
