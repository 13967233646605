import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['collection']

  toggle (event) {
    event.preventDefault()
    event.stopPropagation()
    if (this.hasCollectionTarget) {
      this.collectionTarget.classList.toggle('open')
    }
  }
}
