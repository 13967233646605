import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['string', 'slug']

  updateSlug () {
    if (this.hasSlugTarget && this.hasStringTarget) {
      const stringValue = this.stringTarget.value
      const slugValue = stringValue.replace(/([a-z])([A-Z])/g, '$1-$2')
        .replace(/[\s_]+/g, '-')
        .toLowerCase()
      this.slugTarget.value = slugValue
    }
  }
}
