import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  showToast (field) {
    const toast = document.getElementById('basic-toast')
    const toastText = document.getElementById('toast-text')
    toastText.innerHTML = `${field} updated successfully`
    toast.style.display = 'block'
    setTimeout(() => { toast.style.display = 'none' }, 2000)
  }

  clamp (num, min, max) {
    return Math.min(Math.max(num, min), max)
  }

  updatedField () {
    const elementId = this.element.id
    if (elementId.includes('summary')) {
      return 'Note'
    } else if (elementId.includes('score')) {
      return 'Score'
    } else if (elementId.includes('weight')) {
      return 'Weight percentage'
    } else if (elementId.includes('solution-assessment')) {
      return 'Assessment'
    } else if (elementId.includes('overall-assessment')) {
      return 'Overall assessment'
    } else if (elementId.includes('title')) {
      return 'Analysis title'
    }
  }

  submit (url, method) {
    const csrf = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    fetch(
      url,
      {
        method,
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrf
        }
      }
    ).then(
      response => response.json()
    ).then(
      data => {
        if (this.element.id.includes('summary-button') && data.success) {
          window.location.reload()
        } else if (this.element.id.includes('-weight') && data.success) {
          window.location.reload()
        } else if (data.success) {
          this.showToast(this.updatedField())
        }
      }
    )
  }
}
