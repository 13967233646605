import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['trigger', 'selectable']

  toggle (evt) {
    this.selectableTargets.forEach((checkbox) => {
      checkbox.checked = evt.target.checked
    })
  }
}
