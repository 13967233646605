import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['collapse', 'expand']
  connect () {
    if (document.cookie.includes('side_nav_expanded=true')) {
      this.element.classList.add('expanded')
    } else if (document.cookie.includes('side_nav_expanded=false')) {
      this.element.classList.remove('expanded')
    }
    this.element.addEventListener('keydown', event => {
      if (event.key === ' ' || event.key === 'Enter') {
        event.preventDefault()
        this.toggle(event)
      }
    })
  }

  toggle () {
    const mainContent = document.querySelector('#main-content')
    if (this.element.classList.contains('expanded')) {
      this.element.classList.remove('expanded')
      if (mainContent) {
        mainContent.classList.remove('with-sidebar')
        mainContent.classList.add('with-compact-sidebar')
      }
      this.expandTarget.focus()
      document.cookie = 'side_nav_expanded=false'
    } else {
      this.element.classList.add('expanded')
      if (mainContent) {
        mainContent.classList.add('with-sidebar')
        mainContent.classList.remove('with-compact-sidebar')
      }
      this.collapseTarget.focus()
      document.cookie = 'side_nav_expanded=true'
    }
  }
}
