import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['tab']

  activateTab (event) {
    this.tabTargets.forEach(tab => {
      tab.classList.remove('filtering-tab--active')
    })

    const thisTab = event.currentTarget.closest('.filtering-tab')
    if (thisTab) {
      thisTab.classList.add('filtering-tab--active')
    }
  }
}
