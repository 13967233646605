import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['tooltip']

  enableExpansion () {
    this.tooltipTarget.addEventListener('keydown', event => {
      if (event.key === 'Enter' || event.key === ' ') {
        this.tooltipTarget.classList.toggle('expanded')
      }
      if (event.key === 'Escape') {
        this.tooltipTarget.classList.remove('expanded')
      }
    })
    window.addEventListener('click', () => {
      this.tooltipTarget.classList.remove('expanded')
    })
  }

  disableExpansion () {
    this.tooltipTarget.removeEventListener('keydown', event => {
      if (event.key === 'Enter' || event.key === ' ') {
        this.tooltipTarget.classList.toggle('expanded')
      }
      if (event.key === 'Escape') {
        this.tooltipTarget.classList.remove('expanded')
      }
    })
    window.removeEventListener('click', () => {
      this.tooltipTarget.classList.remove('expanded')
    })
    this.tooltipTarget.classList.remove('expanded')
  }
}
