import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['wrapper', 'trigger', 'icon', 'minimise']

  connect () {
    if (this.wrapperTarget.dataset.hasContent === 'true') {
      setTimeout(() => { this.openSidebar() }, 50)
    }
  }

  toggleSidebar () {
    if (this.wrapperTarget.classList.contains('expanded')) {
      this.closeSidebar()
    } else {
      this.openSidebar()
    }
  }

  openSidebar () {
    this.wrapperTarget.classList.add('expanded')
    this.wrapperTarget.classList.remove('minimised')
    this.wrapperTarget.setAttribute('data-controller', 'modal')

    this.wrapperTarget.setAttribute('aria-expanded', 'true')
    this.wrapperTarget.removeAttribute('inert')
  }

  closeSidebar () {
    this.wrapperTarget.classList.remove('expanded')
    this.wrapperTarget.classList.remove('no-transition')
    this.wrapperTarget.setAttribute('aria-expanded', 'false')
    this.wrapperTarget.setAttribute('inert', 'true')

    if (this.hasTriggerTarget) {
      this.triggerTarget.focus()
    }
  }

  minimiseSidebar () {
    if (this.wrapperTarget.classList.contains('minimised')) {
      this.openSidebar()
    } else {
      this.wrapperTarget.classList.remove('expanded')
      this.wrapperTarget.classList.add('minimised')
    }
    this.wrapperTarget.classList.remove('no-transition')
    this.toggleIcon()
    this.removeFocus()
  }

  toggleIcon () {
    this.iconTarget.classList.toggle('fa-greater-than')
    this.iconTarget.classList.toggle('fa-less-than')
  }

  removeFocus () {
    if (this.wrapperTarget.classList.contains('minimised')) {
      this.minimiseTarget.addEventListener('keydown', event => {
        if (event.shiftKey && event.keyCode === 9) {
          this.minimiseTarget.blur()
        }
      })
    }
  }
}
