import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['shiftLeft', 'shiftRight', 'item']
  static values = { scrollIndex: Number, itemCount: Number }

  connect () {
    this.startAutoScroll()
  }

  startAutoScroll () {
    this.autoScrollTimer = setTimeout(() => this.shiftRight(), 2000)
  }

  shiftLeft () {
    this.scrollIndexValue -= 1
    if (this.scrollIndexValue < 0) this.scrollIndexValue = this.itemCountValue - 1
    this.refreshView()
  }

  shiftRight () {
    this.scrollIndexValue += 1
    if (this.scrollIndexValue >= this.itemCountValue) this.scrollIndexValue = 0
    this.refreshView()
  }

  refreshView () {
    clearTimeout(this.autoScrollTimer)
    this.itemTargets.forEach((item, index) => {
      if (index >= this.scrollIndexValue) {
        item.classList.remove('hidden')
      } else {
        item.classList.add('hidden')
      }
    })
    this.startAutoScroll()
  }
}
