// import ApplicationController from './application_controller'
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  dismissElement (evt) {
    evt.preventDefault()
    const dismissId = evt.target.dataset.dismissTarget
    const dismissTarget = document.getElementById(dismissId)
    if (dismissTarget) {
      dismissTarget.remove()
    }
  }
}
