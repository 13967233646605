import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['trigger', 'wrapper']

  menuClosed () {
    return this.wrapperTarget.classList.contains('hidden')
  }

  closeMenu (shouldFocus = true) {
    if (this.menuClosed()) return

    this.wrapperTarget.classList.toggle('hidden')
    this.triggerTarget.setAttribute('aria-expanded', false)

    if (shouldFocus) this.triggerTarget.focus()
  }

  makeClosable () {
    window.addEventListener('keydown', event => {
      if (event.key === 'Escape') {
        this.closeMenu()
      }
    })

    window.addEventListener('click', () => {
      this.closeMenu(false)
    })
  }

  toggle (evt) {
    evt.preventDefault()
    evt.stopPropagation()

    if (this.menuClosed()) {
      this.wrapperTarget.classList.remove('hidden')
      this.triggerTarget.setAttribute('aria-expanded', true)

      this.makeClosable()
    } else {
      this.closeMenu()
    }
  }
}
