import ApplicationController from './application_controller'

export default class extends ApplicationController {
  updateLabel (event) {
    const fileUploadArea = event.target.closest('.dm-file-upload')
    const label = fileUploadArea.querySelector('.dm-file-upload-filename')
    if (label === null) return

    const fileName = event.target.files[0].name
    label.textContent = fileName
  }

  enableSubmit (event) {
    const fileUploadArea = event.target.closest('.dm-file-upload')
    const submitButton = fileUploadArea.querySelector('.dm-file-upload-submit')
    if (submitButton === null) return

    submitButton.disabled = false
  }
}
