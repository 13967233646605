import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['button', 'details', 'form']

  toggle (event) {
    event.stopPropagation()
    const detailsTarget = event.target.closest('details')
    const closing = detailsTarget && detailsTarget.hasAttribute('open')

    if (!closing) {
      this.detailsTargets.forEach((element) => {
        element.removeAttribute('open')
      })
    }
  }

  closeAll () {
    const openDetails = this.detailsTargets.filter((element) => {
      return element.hasAttribute('open')
    })
    if (openDetails.length > 0) {
      openDetails.forEach((element) => {
        element.removeAttribute('open')
      })
    }
    return openDetails.length
  }

  closeAllAndSubmit () {
    const closeCount = this.closeAll()
    if (closeCount > 0) {
      this.formTarget.requestSubmit()
    }
  }

  onFocusOut (event) {
    const focusedDataTarget = event.relatedTarget.getAttribute('data-target')
    if (focusedDataTarget !== 'details_button') this.closeAll()
  }
}
