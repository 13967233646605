import { Controller } from '@hotwired/stimulus'
import { Turbo } from '@hotwired/turbo-rails'

export default class extends Controller {
  static values = { url: String, interval: Number }

  connect () {
    if (this.hasIntervalValue) {
      this.startCheckingForNewMessages()
    }
  }

  disconnect () {
    this.stopCheckingForNewMessages()
  }

  startCheckingForNewMessages () {
    this.refreshTimer = setInterval(() => {
      this.checkForNewMessages()
    }, this.intervalValue)
  }

  stopCheckingForNewMessages () {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  checkForNewMessages () {
    fetch(this.urlValue, {
      headers: {
        Accept: 'text/vnd.turbo-stream.html'
      }
    }).then(response => {
      if (response.status === 200) {
        return response.text()
      } else {
        return null
      }
    }).then(html => {
      if (typeof html === 'string') {
        Turbo.renderStreamMessage(html)
      }
    })
  }
}
